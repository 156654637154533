/**
 * From github.com/papakay/selectize-bootstrap-4-style
 * Pasted and modified here because that repo bundles bootstrap and won't work with bootswatch
 */

$selectize-font-family: inherit !default;
$selectize-font-size: inherit !default;
$selectize-line-height: $input-btn-line-height !default; //formerly line-height-computed

$selectize-color-text: gray("800") !default; //$gray-800
$selectize-color-highlight: rgba(255,237,40,0.4) !default;
$selectize-color-input: $input-bg !default;
$selectize-color-input-full: $input-bg !default;
$selectize-color-input-error: theme-color("danger") !default;
$selectize-color-input-error-focus: darken($selectize-color-input-error, 10%) !default;
$selectize-color-disabled: $input-bg !default;
$selectize-color-item: #efefef !default;
$selectize-color-item-border: #999 !default;
$selectize-color-item-active: $component-active-bg !default;
$selectize-color-item-active-text: #fff !default;
$selectize-color-item-active-border: rgba(0,0,0,0) !default;
$selectize-color-optgroup: $dropdown-bg !default;
$selectize-color-optgroup-text: $dropdown-header-color !default;
$selectize-color-optgroup-border: $dropdown-divider-bg !default;
$selectize-color-dropdown: $dropdown-bg !default;
$selectize-color-dropdown-border-top: mix($input-border-color, $input-bg, 0.8) !default;
$selectize-color-dropdown-item-active: $dropdown-link-hover-bg !default;
$selectize-color-dropdown-item-active-text: $dropdown-link-hover-color !default;
$selectize-color-dropdown-item-create-active-text: $dropdown-link-hover-color !default;
$selectize-opacity-disabled: 0.5 !default;
$selectize-shadow-input: none !default;
$selectize-shadow-input-focus: inset 0 1px 2px rgba(0,0,0,0.15) !default;
$selectize-shadow-input-error: inset 0 1px 1px rgba(0, 0, 0, .075) !default;
$selectize-shadow-input-error-focus: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px lighten($selectize-color-input-error, 20%) !default;
$selectize-border: 1px solid $input-border-color !default;
$selectize-border-radius: $input-border-radius !default;

$selectize-width-item-border: 0 !default;
$selectize-padding-x: $input-btn-padding-x !default;
$selectize-padding-y: $input-btn-padding-y !default;
$selectize-padding-dropdown-item-x: $input-btn-padding-x !default;
$selectize-padding-dropdown-item-y: 3px !default;
$selectize-padding-item-x: 6px !default;
$selectize-padding-item-y: 2px !default;
$selectize-margin-item-x: 3px !default;
$selectize-margin-item-y: 3px !default;
$selectize-caret-margin: 0 !default;

$selectize-arrow-size: 5px !default;
$selectize-arrow-color: $selectize-color-text !default;
$selectize-arrow-offset: calc(#{$selectize-padding-x} + 5px) !default;


// Here was import selectize.scss
// base styles

$selectize-font-family: inherit !default;
$selectize-font-smoothing: inherit !default;
$selectize-font-size: 13px !default;
$selectize-line-height: 18px !default;

$selectize-color-text: #303030 !default;
$selectize-color-border: #d0d0d0 !default;
$selectize-color-highlight: rgba(125,168,208,0.2) !default;
$selectize-color-input: #fff !default;
$selectize-color-input-full: $selectize-color-input !default;
$selectize-color-disabled: #fafafa !default;
$selectize-color-item: #f2f2f2 !default;
$selectize-color-item-text: $selectize-color-text !default;
$selectize-color-item-border: #d0d0d0 !default;
$selectize-color-item-active: #e8e8e8 !default;
$selectize-color-item-active-text: $selectize-color-text !default;
$selectize-color-item-active-border: #cacaca !default;
$selectize-color-dropdown: #fff !default;
$selectize-color-dropdown-border: $selectize-color-border !default;
$selectize-color-dropdown-border-top: #f0f0f0 !default;
$selectize-color-dropdown-item-active: #f5fafd !default;
$selectize-color-dropdown-item-active-text: #495c68 !default;
$selectize-color-dropdown-item-create-text: rgba(red($selectize-color-text), green($selectize-color-text), blue($selectize-color-text), 0.5) !default;
$selectize-color-dropdown-item-create-active-text: $selectize-color-dropdown-item-active-text !default;
$selectize-color-optgroup: $selectize-color-dropdown !default;
$selectize-color-optgroup-text: $selectize-color-text !default;
$selectize-lighten-disabled-item: 30% !default;
$selectize-lighten-disabled-item-text: 30% !default;
$selectize-lighten-disabled-item-border: 30% !default;
$selectize-opacity-disabled: 0.5 !default;

$selectize-shadow-input: inset 0 1px 1px rgba(0,0,0,0.1) !default;
$selectize-shadow-input-focus: inset 0 1px 2px rgba(0,0,0,0.15) !default;
$selectize-border: 1px solid $selectize-color-border !default;
$selectize-dropdown-border: 1px solid $selectize-color-dropdown-border !default;
$selectize-border-radius: $input-border-radius !default;

$selectize-width-item-border: 0 !default;
$selectize-max-height-dropdown: 200px !default;

$selectize-padding-x: 8px !default;
$selectize-padding-y: 8px !default;
$selectize-padding-item-x: 6px !default;
$selectize-padding-item-y: 2px !default;
$selectize-padding-dropdown-item-x: $selectize-padding-x !default;
$selectize-padding-dropdown-item-y: 5px !default;
$selectize-margin-item-x: 3px !default;
$selectize-margin-item-y: 3px !default;

$selectize-arrow-size: 5px !default;
$selectize-arrow-color: #808080 !default;
$selectize-arrow-offset: 15px !default;

$selectize-caret-margin: 0 2px 0 0 !default;
$selectize-caret-margin-rtl: 0 4px 0 -2px !default;

@mixin selectize-border-radius($radii){
  -webkit-border-radius: $radii;
  -moz-border-radius: $radii;
  border-radius: $radii;
}
@mixin selectize-unselectable(){
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
@mixin selectize-box-shadow($shadow){
  -webkit-box-shadow: $shadow;
  box-shadow: $shadow;
}
@mixin selectize-box-sizing($type: border-box){
  -webkit-box-sizing: $type;
  -moz-box-sizing: $type;
  box-sizing: $type;
}
@mixin selectize-vertical-gradient($color-top, $color-bottom){
  background-color: mix($color-top, $color-bottom, 60%);
  background-image: -moz-linear-gradient(top, $color-top, $color-bottom); // FF 3.6+
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from($color-top), to($color-bottom)); // Safari 4+, Chrome 2+
  background-image: -webkit-linear-gradient(top, $color-top, $color-bottom); // Safari 5.1+, Chrome 10+
  background-image: -o-linear-gradient(top, $color-top, $color-bottom); // Opera 11.10
  background-image: linear-gradient(to bottom, $color-top, $color-bottom); // Standard, IE10
  background-repeat: repeat-x;
  //filter: e(%("progid:DXImageTransform.Microsoft@include gradient(startColorstr='%d', endColorstr='%d', GradientType=0)",argb($color-top),argb($color-bottom))); // IE9 and down
}

.selectize-control {
  position: relative;
}

.selectize-dropdown, .selectize-input, .selectize-input input {
  color: $selectize-color-text;
  font-family: $selectize-font-family;
  font-size: $selectize-font-size;
  line-height: $selectize-line-height;
  -webkit-font-smoothing: $selectize-font-smoothing;
}

.selectize-input, .selectize-control.single .selectize-input.input-active {
  background: $selectize-color-input;
  cursor: text;
  display: inline-block;
}

.selectize-input {
  border: $selectize-border;
  padding: $selectize-padding-y $selectize-padding-x;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  position: relative;
  z-index: 1;
  @include selectize-box-sizing(border-box);
  @include selectize-box-shadow($selectize-shadow-input);
  @include selectize-border-radius($selectize-border-radius);

  .selectize-control.multi &.has-items {
	$padding-x: $selectize-padding-x;
	$padding-top: calc(#{$selectize-padding-y} - #{$selectize-padding-item-y} - #{$selectize-width-item-border});
	$padding-bottom: calc(#{$selectize-padding-y} - #{$selectize-padding-item-y} - #{$selectize-margin-item-y} - #{$selectize-width-item-border});
	padding: $padding-top $padding-x $padding-bottom;
  }

  &.full {
	background-color: $selectize-color-input-full;
  }
  &.disabled, &.disabled * {
	cursor: default !important;
  }
  &.focus {
	@include selectize-box-shadow($selectize-shadow-input-focus);
  }
  &.dropdown-active {
	@include selectize-border-radius($selectize-border-radius $selectize-border-radius 0 0);
  }

  > * {
	vertical-align: baseline;
	display: -moz-inline-stack;
	display: inline-block;
	zoom: 1;
	*display: inline;
  }
  .selectize-control.multi & > div {
	cursor: pointer;
	margin: 0 $selectize-margin-item-x $selectize-margin-item-y 0;
	padding: $selectize-padding-item-y $selectize-padding-item-x;
	background: $selectize-color-item;
	color: $selectize-color-item-text;
	border: $selectize-width-item-border solid $selectize-color-item-border;

	&.active {
	  background: $selectize-color-item-active;
	  color: $selectize-color-item-active-text;
	  border: $selectize-width-item-border solid $selectize-color-item-active-border;
	}
  }
  .selectize-control.multi &.disabled > div {
	&, &.active {
	  color: lighten(desaturate($selectize-color-item-text, 100%), $selectize-lighten-disabled-item-text);
	  background: lighten(desaturate($selectize-color-item, 100%), $selectize-lighten-disabled-item);
	  border: $selectize-width-item-border solid lighten(desaturate($selectize-color-item-border, 100%), $selectize-lighten-disabled-item-border);
	}
  }
  > input {
	&::-ms-clear {
	  display: none;
	}
	display: inline-block !important;
	padding: 0 !important;
	min-height: 0 !important;
	max-height: none !important;
	max-width: 100% !important;
	margin: $selectize-caret-margin !important;
	text-indent: 0 !important;
	border: 0 none !important;
	background: none !important;
	line-height: inherit !important;
	-webkit-user-select: auto !important;
	@include selectize-box-shadow(none);
	&:focus { outline: none !important; }
  }
}

.selectize-input::after {
  content: ' ';
  display: block;
  clear: left;
}

.selectize-input.dropdown-active::before {
  content: ' ';
  display: block;
  position: absolute;
  background: $selectize-color-dropdown-border-top;
  height: 1px;
  bottom: 0;
  left: 0;
  right: 0;
}

.selectize-dropdown {
  position: absolute;
  z-index: 10;
  border: $selectize-dropdown-border;
  background: $selectize-color-dropdown;
  margin: -1px 0 0 0;
  border-top: 0 none;
  @include selectize-box-sizing(border-box);
  @include selectize-box-shadow(0 1px 3px rgba(0,0,0,0.1));
  @include selectize-border-radius(0 0 $selectize-border-radius $selectize-border-radius);

  [data-selectable] {
	cursor: pointer;
	overflow: hidden;
	.highlight {
	  background: $selectize-color-highlight;
	  @include selectize-border-radius(1px);
	}
  }
  .option, .optgroup-header {
	padding: $selectize-padding-dropdown-item-y $selectize-padding-dropdown-item-x;
  }
  .option, [data-disabled], [data-disabled] [data-selectable].option {
	cursor: inherit;
	opacity: 0.5;
  }
  [data-selectable].option {
	opacity: 1;
  }
  .optgroup:first-child .optgroup-header {
	border-top: 0 none;
  }
  .optgroup-header {
	color: $selectize-color-optgroup-text;
	background: $selectize-color-optgroup;
	cursor: default;
  }
  .active {
	background-color: $selectize-color-dropdown-item-active;
	color: $selectize-color-dropdown-item-active-text;
	&.create {
	  color: $selectize-color-dropdown-item-create-active-text;
	}
  }
  .create {
	color: $selectize-color-dropdown-item-create-text;
  }
}

.selectize-dropdown-content {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: $selectize-max-height-dropdown;
  -webkit-overflow-scrolling: touch;
}

.selectize-control.single .selectize-input {
  &, input { cursor: pointer; }
  &.input-active, &.input-active input { cursor: text; }

  &:after {
	content: ' ';
	display: block;
	position: absolute;
	top: 50%;
	right: $selectize-arrow-offset;
	margin-top: round((-1 * $selectize-arrow-size / 2));
	width: 0;
	height: 0;
	border-style: solid;
	border-width: $selectize-arrow-size $selectize-arrow-size 0 $selectize-arrow-size;
	border-color: $selectize-arrow-color transparent transparent transparent;
  }
  &.dropdown-active:after {
	margin-top: $selectize-arrow-size * -0.8;
	border-width: 0 $selectize-arrow-size $selectize-arrow-size $selectize-arrow-size;
	border-color: transparent transparent $selectize-arrow-color transparent;
  }
}

.selectize-control.rtl {
  &.single .selectize-input:after {
	left: $selectize-arrow-offset;
	right: auto;
  }
  .selectize-input > input {
	margin: $selectize-caret-margin-rtl !important;
  }
}

.selectize-control .selectize-input.disabled {
  opacity: $selectize-opacity-disabled;
  background-color: $selectize-color-disabled;
}
// end import selectize.scss

// Plugin: remove_buton
.selectize-control.plugin-remove_button {
    [data-value] {
      position: relative;
      padding-right: 24px !important;
    }
    [data-value] .remove {
      z-index: 1; /* fixes ie bug (see #392) */
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 17px;
      text-align: center;
      font-weight: bold;
      font-size: 12px;
      color: inherit;
      text-decoration: none;
      vertical-align: middle;
      display: inline-block;
      padding: $selectize-padding-item-y 0 0 0;
      border-left: 1px solid $selectize-color-item-border;
      @include selectize-border-radius(0 0px 0px 0);
      @include selectize-box-sizing(border-box);
    }
    [data-value] .remove:hover {
      background: rgba(0,0,0,0.05);
    }
    [data-value].active .remove {
      border-left-color: $selectize-color-item-active-border;
    }
    .disabled [data-value] .remove:hover {
      background: none;
    }
    .disabled [data-value] .remove {
      border-left-color: lighten(desaturate($selectize-color-item-border, 100%), $selectize-lighten-disabled-item-border);
    }
    .remove-single {
      position: absolute;
      right: 0;
      top: 0;
      font-size: 23px;
    }
  }

// End plugin: remove_button

.selectize-dropdown, .selectize-dropdown.form-control {
  height: auto;
  padding: 0;
  margin: 2px 0 0 0;
  z-index: $zindex-dropdown;
  background: $selectize-color-dropdown;
  border: 1px solid $dropdown-border-color; //$dropdown-fallback-border
  border: 1px solid $dropdown-border-color; //$dropdown-border;
  @include selectize-border-radius($border-radius);
  @include selectize-box-shadow(0 6px 12px rgba(0,0,0,.175));
}

.selectize-dropdown {
  .optgroup-header {
	font-size: $font-size-sm;
	line-height: $line-height-base;
  }
  .optgroup:first-child:before {
	display: none;
  }
  .optgroup:before {
	content: ' ';
	display: block;
	@include nav-divider();
	margin-left: $selectize-padding-dropdown-item-x * -1;
	margin-right: $selectize-padding-dropdown-item-x * -1;
  }

  .create {
	padding-left: $selectize-padding-dropdown-item-x;
  }
}

.selectize-dropdown-content {
  padding: 5px 0;
}

.selectize-dropdown-header {
  padding: $selectize-padding-dropdown-item-y * 2 $selectize-padding-dropdown-item-x;
}

.selectize-input {
  min-height: $input-height;

  &.dropdown-active {
	@include selectize-border-radius($selectize-border-radius);
  }
  &.dropdown-active::before {
	display: none;
  }
  &.focus {
	$color: $gray-500;
	//$color-rgba: rgba(red($color), green($color), blue($color), .6);
	border-color: $color;
	outline: 0;
	@include selectize-box-shadow(none)
  	//@include selectize-box-shadow(#{"inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px ${color-rgba}"});
  }
}

.has-error .selectize-input {
  border-color: $selectize-color-input-error;
  @include selectize-box-shadow($selectize-shadow-input-error);

  &:focus {
	border-color: $selectize-color-input-error-focus;
	@include selectize-box-shadow(none)
	//@include selectize-box-shadow($selectize-shadow-input-error-focus);
  }
}

.selectize-control {
  &.multi {
	.selectize-input.has-items {
	  padding-left: calc(#{$selectize-padding-x} - #{$selectize-padding-item-x});
	  padding-right: calc(#{$selectize-padding-x} - #{$selectize-padding-item-x});
	}
	.selectize-input > div {
	  @include selectize-border-radius(calc(#{$selectize-border-radius} - 1px));
	}
  }
}

.form-control.selectize-control {
  padding: 0;
  height: auto;
  border: none;
  background: none;
  @include selectize-box-shadow(none);
  @include selectize-border-radius(0);
}