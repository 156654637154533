.pause-counter {
	font-size: 1.2em;
}

.queue-progresses .progress {
	min-height: 1.5em;
}

.display .panel { color: white; }
.display .table { color: white; }

@media (max-width: 1400px) {
  .table-connect.table {
	margin-top: -44px !important;
  }

  .table-connect thead{
	visibility: hidden !important;
  }

  .table-connect th {
	border-bottom: 0px !important;
  }

}

.display {
  background-color: #272b30;
  width: 104%;
  margin-left: -2%;
  margin-right: 2%;
  padding-left: 2%;
  padding-right: 2%;
}

.display .container {
  background-color: #272b30;
  width: 100%;
}

.display .panel {
  background-color: transparent;
  text-align: center;
}
.display .panel-heading {
  font-size: 6em;
  text-align: center;
}

.display .panel-body {
  font-size: 16em;
  font-size: 10vw;
  padding-top: 0;
  padding-bottom: 0;
  font-weight: bold;
  line-height: 1em;
}

.display .progress {
  height: 5em;
  background-color: #333;
  border: 1px solid #888;
}

.display .btn
{
  text-align: left;
}

.display .btn span{
  font-size: 1.6em;
  line-height: 0;
  vertical-align: middle;
}

.display th {
  font-size: 2em;
  text-align: center;
}

.display .btn-success {
  background: #5CB85C;
}

.display td {
  text-align: center;
}

.display td > span > .glyphicon {
  line-height: 0.2em;
}

.display .info-panel {
  padding: 0.5%;
}

.btn.status {
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 1.5em;
}

#extension-list .btn {
  /*font-size: 1.5em;*/
}

#extension-list .agent-status-icon {
  float: left;
}

#extension-list .btn.phone-status {
  font-weight: bold;
  padding-top: 2px;
  padding-bottom: 2px;
  border-right: 2px solid #FFF;
  font-size: 1.5em;
  width: 80%;
}

#extension-list .agent-status {
  background: red;
  color: #EEE;
}
#extension-list .agent-status:after {
  content: "Not Available"
}

#extension-list .agent-status.Ok,
#extension-list .agent-status.Registered,
#extension-list .agent-status.Reachable {
  background: #428BCA;
}
#extension-list .agent-status.Ok:after,
#extension-list .agent-status.Registered:after,
#extension-list .agent-status.Reachable:after {
  content: "Available";
}

#extension-list .agent-status.Ringing {
  background: orange;
}
#extension-list .agent-status.Ringing:after {
  content: "Ringing";
}

#extension-list .agent-status.Up {
  background: green;
}
#extension-list .agent-status.Up:after {
  content: "In Call";
}

.agent-status-label {
display: inline-block;
width: 100%;
font-size: 12px;
padding: 6px 12px;
line-height: 1.428571429;
white-space: normal;
}

#extension-list > div > table.table td {
height: auto;
}

#extension-list > div > table.table  > tbody {
  border-color: #DDDDDD;
  border-width: 0;
  border-bottom-width: 0px;
}

#extension-list > div:first-child > table.table > tbody {
  border-bottom-width: 1px;
}

#extension-list > div > table.table > thead > tr.extension-table-head-row > th {
border-top: none;
}

#extension-list > div >  table.table > thead > tr.extension-table-head-row {
  display: none;
}

#extension-list > div:first-child > table.table > thead > tr.extension-table-head-row {
  display: table-row;
}

@media (min-width: 1400px) {
	#extension-list > div > table.table > thead > tr.extension-table-head-row {
		display: table-row;
	}

  	#extension-list > div:first-child > table.table > tbody {
		border-bottom-width: 0px;
	}
}

.progress.queue-progress {
  height: 40px;
  margin-bottom: 0;
}

.progress.queue-progress  > .progress-bar {
	font-size: 2.2em;
	line-height: 40px;
	text-shadow:
		-1px -1px 0 rgba(0, 0, 0, 0.2),
		1px -1px 0 rgba(0, 0, 0, 0.2),
		-1px  1px 0 rgba(0, 0, 0, 0.2),
		1px  1px 0 rgba(0, 0, 0, 0.2);
}

.callcenter-contact-display {
	display: block;
	text-overflow: ellipsis;
	overflow: hidden;
}