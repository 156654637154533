#player-progress-slider .slider-selection {
	background: theme-color('primary');
}

#player-volume-slider .slider-selection {
	background: theme-color('primary');
}

#player-progress-slider .slider-handle, #player-volume-slider .slider-handle {
	background: theme-color('primary');
}

#player-progress-slider .slider-handle {
	transition-timing-function: linear;
}

#player-progress-slider .slider-track {
	background: theme-color('light');
}

#player-volume-slider .slider-track {
	background: theme-color('light');

}

#player-container .btn:focus {
	box-shadow: none;
}

#player-button-volume-control-toggle:focus {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.slider-disabled {
	opacity: 0.5;
}