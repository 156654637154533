@include media-breakpoint-up('xl') {
	.ticket-card {
		> .card-block {
			height: calc(100vh - 200px);
			min-height: 0;

			> .row {
				height: 100%;
			}
		}

		.ticket-first-pane {
			height: 100%;
			overflow-y: auto;
		}

		.ticket-second-pane {
			height: 100%;
			display: flex;
			flex-direction: column;

			> .nav {
				flex-shrink: 1;
				flex-grow: 0;
			}

			> .tab-content {
				flex-shrink: 0;
				flex-grow: 1;
				flex-basis: 0;
				overflow-y: auto;
			}
		}
	}
}

.ticket-share-list,
.ticket-reminders-list {
	list-style: none;
	padding-left: 0;
}

.reminder-done {
	text-decoration: line-through;
}

.ticket-type-sidebar,
.card-group > .ticket-type-sidebar {
	width: 12%;
	min-width: 150px;
	padding: 0;
	flex: 0 0 auto;
}

.ticket-type-sidebar-item {
	overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    white-space: nowrap;
    text-align: center;
}