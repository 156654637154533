@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.snow.css";

.editor-template-placeholder {
    background: #EEEEEE;
    border-radius: 4px;
    padding: 1px 6px;
    border: 1px solid #DDDDDD;
    box-sizing: border-box;
  }

.ql-picker.ql-template {
    > span.ql-picker-label::before {
      content: 'Templates';
      width: 90px; // No idea why
    }

    > .ql-picker-options > .ql-picker-item::before {
      content: attr(data-value);
    }
}

.clickvox-quote-wrapper {
    border-left: 2px solid theme-color('secondary');
    padding-left: map-get($spacers, 3);
}

@include media-breakpoint-up('lg') {
    .mails-wrapper {
        height: calc(100vh - 88px);
    }
}

.mails-wrapper > .mails-split {
    min-height: 0;
    height: 100%;

    > .card {
        height: 100%;
        &:not(:first-child) {
            &,
            > .card-header {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }
        &:not(:last-child) {
            &,
            > .card-header {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
    }
}

.new-threads-indicator {
    border: none;
    border-radius: 0;
}

.mail-thread-list-item {
    &:not(.seen) {
        @extend .list-group-item-secondary;
        .mail-message-subject {
            font-weight: bold;
        }
    }
}

.cancel-edit-btn {
    font-size: 1.3em;
}