@include media-breakpoint-up('xl') {
	.contact-card {
		> .card-block {
			height: calc(100vh - 150px);
			min-height: 0;

			> .row {
				height: 100%;
			}
		}

		.contact-first-pane {
			height: 100%;
			overflow-y: auto;
		}

		.contact-second-pane {
			height: 100%;
			display: flex;
			flex-direction: column;

			> .nav {
				flex-shrink: 1;
				flex-grow: 0;
			}

			> .tab-content {
				flex-shrink: 0;
				flex-grow: 1;
				flex-basis: 0;
				overflow-y: auto;
			}
		}
	}
}