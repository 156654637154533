.settings-menu-list-group {
    .list-group-item {
        border-left: none;
        border-right: none;
        border-radius: 0;
    }

    .list-group-item:first-child {
        border-top: none;
    }
}

@include media-breakpoint-up(sm) {
    .bq-settings {
        height: calc(100vh - #{$navbar-height});
    }

    .settings-card-block {
        flex-grow: 1;
    }

    .settings-container-row > * {
        min-height: 100%;

        &:first-child {
            // border-right: $border-width solid $border-color;
        }
    }
}