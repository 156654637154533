@import "~bootstrap-vue/dist/bootstrap-vue.css";
@import "~bootstrap-daterangepicker/daterangepicker.css";
@import "~bootstrap-slider/dist/css/bootstrap-slider.css";
@import "~spin.js/spin.css";

@import "app/Modules/Menu/Assets/css/main.scss";
@import "app/Modules/Settings/Assets/css/main.scss";
@import "app/Modules/Player/Assets/css/main.scss";
@import "app/Modules/Callcenter/Assets/css/main.scss";
@import "app/Modules/Dialer/Assets/css/main.scss";
@import "app/Modules/Mails/Assets/css/main.scss";
@import "app/Modules/Ticketing/Assets/css/main.scss";
@import "app/Modules/Notifications/Assets/css/main.scss";
@import "app/Modules/Campaigns/Assets/css/main.scss";
@import "app/Modules/Sms/Assets/css/main.scss";

@import "app/assets/css/pickle.scss";
@import "selectize.scss";
@import "formEditor.scss";

.ql-container,
.ql-editor {
  @if $enable-rounded {
    border-radius: $input-border-radius;
  } @else {
    border-radius: 0;
  }
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.ql-editor {
  @include box-shadow($input-box-shadow);
  @include transition($input-transition);

  @include form-control-focus();
}

.col-form-label {
  font-weight: bold;
}

.stick-bottom {
	position: sticky;
	bottom: 0;
}

.stick-top {
	position: sticky;
	top: $navbar-height;
}

.stick-top, .stick-bottom {
	z-index: 100;
}

body,
html,
#content-wrapper,
#main-container {
	width: 100%;
}

.modal-content .stick-top {
	top: 0px;
}

.datatable tr.animate-row {
	transition: background-color 2s;
}

.popover {
	max-width: none;
	width: auto;
}

.card-header.stick-top,
.card-header.stick-bottom,
.card-footer.stick-top,
.card-footer.stick-bottom {
	background-color: darken($card-bg, 3%);
}

$subform-card-color: if(variable-exists(subform-card-color), $subform-card-color, theme-color('light'));

.subform-card {
  background-color: $subform-card-color;
}

.form-error-info {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
}

@include media-breakpoint-down('sm') {
    .nav-tabs {
        .nav-item {
            display: flex;
            flex: 1 1 0;
            text-align: center;
        }

        .nav-link {
            width: 100%;
            padding: map-get($spacers, 1) map-get($spacers, 1);
        }

        .tab-icon {
            display: block;
            width: 100%;
            text-align: center;
        }

        .tab-text {
            display: block;
            font-size: 0.75em;
        }
    }

    .page-selector {
        width: 100%;
    }
}

.header-buttons > * {
    padding: 0px map-get($spacers, 2);

    &:first-child {
        padding-left: 0px;
    }

    &:last-child {
        padding-right: 0px;
    }
}

.btn-yellow {
  background: color('yellow');
  color: color('white');
}

a.btn-yellow:hover {
  background: darken(color('yellow'), 1);
  color: color('white');
}

.btn-orange {
  background: color('orange');
  color: color('white');
}

.btn-blue {
  background: color('blue');
  color: color('white');
}

.btn-green {
  background: color('green');
  color: color('white');
}

.btn-red {
  background: color('red');
  color: color('white');
}

.badge-yellow {
  background: color('yellow');
  color: color('white');
}

.badge-orange {
  background: color('orange');
  color: color('white');
}

.badge-blue {
  background-color: color('blue');
  color: color('white');
}

.text-blue {
  color: color('blue');
}

.text-green {
  color: color('green');
}

.text-red {
  color: color('red');
}

.text-orange {
  color: color('orange');
}

.text-yellow {
  color: color('yellow');
}

//
// Switches for Bootstrap 4.
//
// - Fully customizable with Sass variables
// - No JavaScript required
// - Fully accessible
//

//
// These variables can be used to customize the switch component.
//
$switch-height: calc(#{$input-height} * .8) !default;
$switch-height-sm: calc(#{$input-height-sm} * .8) !default;
$switch-height-lg: calc(#{$input-height-lg} * .8) !default;
$switch-border-radius: $switch-height !default;
$switch-bg: $custom-control-indicator-bg !default;
$switch-checked-bg: map-get($theme-colors, 'success') !default;
$switch-disabled-bg: $custom-control-indicator-disabled-bg !default;
// $switch-disabled-color: $custom-control-description-disabled-color !default;
$switch-disabled-color: #EEE;
$switch-thumb-bg: $white !default;
$switch-thumb-border-radius: 50% !default;
$switch-thumb-padding: 2px !default;
$switch-focus-box-shadow: 0 0 0 $input-btn-focus-width rgba(map-get($theme-colors, 'success'), .25);
$switch-transition: .2s all !default;

.switch {
  font-size: $font-size-base;
  position: relative;

  input {
    position: absolute;
    height: 1px;
    width: 1px;
    background: none;
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    overflow: hidden;
    padding: 0;

    + label {
      position: relative;
      min-width: calc(#{$switch-height} * 2);
      border-radius: $switch-border-radius;
      height: $switch-height;
      line-height: $switch-height;
      display: inline-block;
      cursor: pointer;
      outline: none;
      user-select: none;
      vertical-align: middle;
      text-indent: calc(calc(#{$switch-height} * 2) + .5rem);
    }

    + label::before,
    + label::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: calc(#{$switch-height} * 2);
      bottom: 0;
      display: block;
    }

    + label::before {
      right: 0;
      background-color: $switch-bg;
      border-radius: $switch-border-radius;
      transition: $switch-transition;
    }

    + label::after {
      top: $switch-thumb-padding;
      left: $switch-thumb-padding;
      width: calc(#{$switch-height} - calc(#{$switch-thumb-padding} * 2));
      height: calc(#{$switch-height} - calc(#{$switch-thumb-padding} * 2));
      border-radius: $switch-thumb-border-radius;
      background-color: $switch-thumb-bg;
      transition: $switch-transition;
    }

    &:checked + label::before {
      background-color: $switch-checked-bg;
    }

    &:checked + label::after {
      margin-left: $switch-height;
    }

    &:focus + label::before {
      outline: none;
      box-shadow: $switch-focus-box-shadow;
    }

    &:disabled + label {
      color: $switch-disabled-color;
      cursor: not-allowed;
    }

    &:disabled + label::before {
      background-color: $switch-disabled-bg;
    }
  }

  // Small variation
  &.switch-sm {
    font-size: $font-size-sm;

    input {
      + label {
        min-width: calc(#{$switch-height-sm} * 2);
        height: $switch-height-sm;
        line-height: $switch-height-sm;
        text-indent: calc(calc(#{$switch-height-sm} * 2) + .5rem);
      }

      + label::before {
        width: calc(#{$switch-height-sm} * 2);
      }

      + label::after {
        width: calc(#{$switch-height-sm} - calc(#{$switch-thumb-padding} * 2));
        height: calc(#{$switch-height-sm} - calc(#{$switch-thumb-padding} * 2));
      }

      &:checked + label::after {
        margin-left: $switch-height-sm;
      }
    }
  }

  // Large variation
  &.switch-lg {
    font-size: $font-size-lg;

    input {
      + label {
        min-width: calc(#{$switch-height-lg} * 2);
        height: $switch-height-lg;
        line-height: $switch-height-lg;
        text-indent: calc(calc(#{$switch-height-lg} * 2) + .5rem);
      }

      + label::before {
        width: calc(#{$switch-height-lg} * 2);
      }

      + label::after {
        width: calc(#{$switch-height-lg} - calc(#{$switch-thumb-padding} * 2));
        height: calc(#{$switch-height-lg} - calc(#{$switch-thumb-padding} * 2));
      }

      &:checked + label::after {
        margin-left: $switch-height-lg;
      }
    }
  }

  + .switch {
    margin-left: 1rem;
  }
}


.dropdown-menu-scrollable {
  max-height: calc(80vh - #{$navbar-height});
  overflow-y: auto;
}

.table-responsive th {
  white-space: nowrap;
}

tr.table-whole-row-action > td {
  position: relative;
}

tr.table-whole-row-action > td a,
tr.table-whole-row-action > td button {
  position: relative;
  z-index: 1;
}

tr.table-whole-row-action > td > a.whole-row-action-anchor {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
}

tr.table-whole-row-action > td > a.whole-row-action-anchor:hover {
  text-decoration: none;
}

tr.table-whole-row-action > td > a.whole-row-action-anchor:focus {
  outline: none;
}

.rowselect-column {
  width: 1%;
}

.form-quill .ql-editor {
  min-height: 60px;
  resize: vertical;
}

.form-section-separator-label {
  margin-top: 1rem;
  margin-bottom: 0.25rem;
  font-weight: bold;
}

.form-section-separator {
  width: 100%;
  margin-top: 0;
}