@import "~bootswatch/dist/flatly/variables";
@import "~bootstrap/scss/bootstrap";
@import "~bootswatch/dist/flatly/bootswatch";

$navbar-height: 54.5px;

$navbar-background: theme-color('primary');
.navbar {
    @extend .navbar-dark;
    background-color: $navbar-background;
}

.navbar-nav .nav-item + .nav-item {
    margin-left: 0;
}

@import '../main.scss';
