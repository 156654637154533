/* Global (for both expanded and folded) */
.menu-container {
	z-index: 1000;
	position: fixed;
	left: 0;
	top: $navbar-height;
	bottom: 0;
	overflow-y: auto;
	overflow-x: hidden;
	font-size: 1.1em;
	transition: width 0.2s ease-in 0s, box-shadow 0.2s ease-in 0s;
}
#main-container {
	margin-left: 0;
	transition: padding-left 0.2s ease-in 0s, margin-left 0.2s ease-in 0s;
}
.menu-container > ul.navbar-nav {
	position: absolute;
	top: 0;
	bottom: 0;
	width: 200px;
}
.menu-item-icon {
	max-width: 50px;
}
.menu-item-label {
	max-width: 150px;
}

.menu-item-label > div {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}
.menu-toggle-item {
	transition: width 0.2s ease-in 0s;
}

/* Expanded whem .menu-expanded set and not on small devices (unless .menu-folded set) */
.menu-container {
	width: 200px;
}
#main-container {
	padding-left: 215px;
}
.menu-item-label {
	display: flex;
}
.menu-toggle-item {
	width: 200px;
}
.menu-expanded .menu-container {
	width: 200px;
}
.menu-expanded #main-container {
	padding-left: 215px;
}
.menu-folded .menu-item-label {
	display: none;
}
.menu-folding .menu-item-label {
	display: flex;
}
.menu-expanded .menu-item-label {
	display: flex;
}
.menu-expanded .menu-toggle-item {
	width: 200px;
}
.menu-folded .menu-container  > .navbar-nav {
	width: 51px;
}
.menu-folding .menu-container > .navbar-nav {
	width: 200px;
}

/* Folded when menu-folded set and on small devices (unless .menu-expanded set) */
@media (max-width: 1440px) {
	#main-container {
		padding-left: 65px;
	}
	.menu-container {
		width: 51px;
	}
	.menu-toggle-item {
		width: 50px;
	}
}
@media(max-width: 960px) {
	body.menu-expanded,
	body.menu-expanding,
	body.menu-folding {
		overflow: hidden;
	}
	.menu-expanded #main-container {
		padding-left: 65px;
		margin-left: 150px;
	}
	#main-container::after {
		content: '';
		position: fixed;
		left: 0; right: 0; top: 0; bottom: 0;
		display: block;
		opacity: 0.0;
		transition: opacity 0.2s ease-in 0s, z-index 0.2s step-end;
		background-color: rgb(52, 58, 64);
		z-index: -1;
	}
	.menu-expanded #main-container::after {
		z-index: 999;
		opacity: 0.75;
		transition: opacity 0.2s ease-in 0s, z-index 0.2s step-start;
	}
	#main-container {
		perspective: 600px;
	}
	#main-container > * {
		transition: transform 0.2s linear 0s;
		transform-origin: top left;
	}

	.menu-folding,
	.menu-expanding,
	.menu-expanded {
		#main-container > * {
			pointer-events: none;
		}
	}
	.menu-expanded .menu-container {
		box-shadow: 4px 0px 6px 1px #444;
	}
}

.menu-folded #main-container {
	padding-left: 65px;
}
.menu-folded .menu-container {
	width: 51px;
}
.menu-folded .menu-toggle-item {
	width: 50px;
}

.mobile-menu-toggle-btn {
	display: none;
}
@media(max-width: 576px) {
	.menu-container,
	#main-container {
		transition: transform 0.2s linear 0s;
	}

	.menu-folded .menu-container,
	.menu-expanded .menu-container {
		width: 200px;
	}

	// Reset to defaults
	.menu-folded #main-container,
	.menu-expanded #main-container,
	#main-container {
		margin: 0;
		padding-left: 15px;
		padding-right: 15px;
	}

	.menu-expanded {
		.menu-container {
			transform: translateX(0px);
		}
		#main-container {
			transform: translateX(200px);
		}
	}

	.menu-folded {
		.menu-container {
			display: none;
			transform: translateX(-200px);
		}
	}

	.menu-folding,
	.menu-expanded {
		.menu-container {
			display: flex;
		}
	}

	.mobile-menu-toggle-btn,
	.navbar-expand .mobile-menu-toggle-btn {
		display: flex;
	}
	.navbar-brand {
		margin-left: auto;
	}
}
@media(max-width: 250px) {
	.menu-expanded .menu-container {
		width: 100%;
	}
}

#main-container.nomenu,
.menu-expanded #main-container.nomenu,
.menu-folded #main-container.nomenu {
	padding-left: 15px;
	padding-right: 15px;
}

.menu-tooltip {
	&[x-placement^="left"] > .arrow::before {
		border-left-color: $navbar-background;
	}
	&[x-placement^="right"] > .arrow::before {
		border-right-color: $navbar-background;
	}
	&[x-placement^="top"] > .arrow::before {
		border-top-color: $navbar-background;
	}
	&[x-placement^="bottom"] > .arrow::before {
		border-bottom-color: $navbar-background;
	}
	> .tooltip-inner {
		background: $navbar-background;
	}
}