.dialer-container {
    background-color: if(variable-exists(callstatus-background), $callstatus-background, darken($navbar-background, 5));
    color: if(variable-exists(callstatus-foreground), $callstatus-foreground, theme-color('light'));
}

.dialer-extension-status-section {
    .Idle {
        @extend .text-green;
    }

    .Ringing {
        @extend .text-orange;
    }

    .InUse, .Busy {
        @extend .text-blue;
    }
}

.callstatus-nav {
    background-color: if(variable-exists(callstatus-background), $callstatus-background, darken($navbar-background, 5));
    color: if(variable-exists(callstatus-foreground), $callstatus-foreground, theme-color('light'));
    // border-radius: $border-radius;
    height: calc(#{$navbar-height} - #{map-get($spacers, 1) * 2} );

    &:hover {
        background-color: darken($navbar-background, 8);
        cursor: pointer;
    }
}