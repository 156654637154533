.notifications-menu {
    width: 480px;
    max-width: 100vw;
    max-height: 80vh;
    display: flex;
}

.notification-list {
    overflow-y: auto;
}

.icon-with-badge {
    position: relative;

    > .badge {
        position: absolute;
        bottom: -4px;
        right: -4px;
        padding: 2px;
        font-size: 64%;
    }
}

.notifications.card > .card-header {
    flex: 1 0 auto;
}