.pickle-container .pickle-addon {
	position: absolute;
	top: 6px;
	right: 12px;
}

.pickle-container {
	position: relative;
	display: flex;
	flex: 1 1 auto;
}

.pickle-container .pickle-input-container {
	padding: 5px 10px;
	top: 0;
	position: absolute;
	z-index: 100;
	width: 100%;
	background: inherit;
	border-bottom: 1px solid #EEE;
}

.pickle-container .pickle-options {
	width: 100%;
	height: auto;
	min-height: 52px;
	max-height: 410px;
	overflow-y: auto;
}

.pickle-single-select-button {
	padding-right: 16px;
}

.pickle-caret {
	display: block;
	position: absolute;
	top: 25%;
	right: 16px;
}

.selected-option  {
	display: block;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	width: 100%;
	padding-right: 9px;
}

.selected-option:after {
	content: '\00A0';
}

.form-control.pickle-button .selected-option:empty:after,
.form-control.pickle-button:hover .selected-option:empty:after {
	content: attr(data-pickle-placeholder) '\00A0';
	display: inline-block;
	font-weight: normal;
	color: #888;
}

.pickle-container.show .pickle-button {
	border-bottom-width: 0;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.pickle-container.show .pickle-options {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	border-top-width: 0;
	margin-top: 0;
}

.pickle-tag {
	float: left;
	margin-right: 0.5em;
	line-height: 1;
}

.pickle-button,
.form-control.pickle-button {
	height: auto;
	width: 100%;
	font-weight: normal;
	text-decoration: none;

	&.disabled,
	&[disabled] {
		opacity: $btn-disabled-opacity;
		@include box-shadow(none);
		pointer-events: none;
	}
}

.pickle-button.form-control {
	cursor: default;
}

.pickle-multi-select-button.form-control {
	cursor: text;
}

.pickle-tag.label {
	font-size: 100%;
	margin-right: 0.5em;
}

.pickle-remove.badge,
.btn .pickle-remove.badge {
	cursor: default;
	opacity: 0.75;
	vertical-align: middle;
	margin-left: 0.5em;
}

.pickle-multi-select-button {
	padding-top: 0.33rem;
	padding-bottom: 0.33rem;
}

.pickle-multi-select-button .selected-option:empty {
	line-height: 1.6;
}


.pickle-remove:hover {
	opacity: 1;
}

.glyphicon.pickle-spinning {
    animation: spin 1s infinite linear;
    -webkit-animation: spin2 1s infinite linear;
}

@keyframes spin {
    from { transform: scale(1) rotate(0deg);}
    to { transform: scale(1) rotate(360deg);}
}

@-webkit-keyframes spin2 {
    from { -webkit-transform: rotate(0deg);}
    to { -webkit-transform: rotate(360deg);}
}

.input-group .pickle-container:not(:last-child) > .pickle-button {
	border-top-right-radius: 0 0;
	border-bottom-right-radius: 0 0;
}

.input-group select:not(:first-child) + .pickle-container > .pickle-button {
	border-top-left-radius: 0 0;
	border-bottom-left-radius: 0 0;
}