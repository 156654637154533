.label-text[contenteditable=true] {
    text-decoration: underline dotted;
    border: 1px dotted transparent;

    &:hover,
    &:focus {
        outline: 0px solid transparent;
        text-decoration: none;
        border: 1px dotted lighten($body-color, 50%);
    }

    &:focus {
        border-color: $body-color;
    }
}

.label-after {
    user-select: none;
}

.editable-field-drag-handle {
    cursor: row-resize;
}
